@import "normalize.css";

* {
  outline: none !important;
}

html,
body {
  overscroll-behavior-x: none;
}

html {
  font-size: 62.5%;
  font-family: Comfortaa, cwTeXYen, Muli, Roboto, Helvetica Neue, Arial,
    sans-serif !important;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 30px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border: 11px solid #ffffff00 !important;
  background-clip: padding-box;
  padding: 2px;
  border-radius: 50px;
  box-shadow: inset 0 0 0 40px #ddd;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 0 40px #ddd;
}

form label {
  z-index: 1;
}

.auth0-lock-shown #fuse-navbar {
  display: none;
}

@import "print.css";

@import "tables.css";

@import "react-table.css";

@import "prism.css";

@import "tailwind.css";

@import "auth0-override.css";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ag-react-container {
  height: 100%;
  display: flex;
}

.swiper-container {
  width: 100%;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-prev.swiper-button-disabled
  + .swiper-button-next.swiper-button-disabled
  + .swiper-pagination {
  display: none;
}
