/**
  Auth0 Lock.js overriding
 */

.auth0-lock.auth0-lock .auth0-lock-content-wrapper {
  overflow: visible !important;
}

@media (min-width: 481px) {
  .auth0-lock-error-msg .auth0-lock-error-invalid-hint {
    width: 180px;
    white-space: pre-line;
  }

  .auth0-lock.auth0-lock .auth0-lock-cred-pane {
    background: unset !important;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 40px 0px #1111133d !important;
    min-height: unset !important;
    height: unset !important;
  }

  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    max-height: unset !important ;
    background: #fff !important;
    border-radius: 6px !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-widget {
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 1px;
    display: flex;
    flex-direction: column;
  }
  .auth0-lock.auth0-lock .auth0-lock-widget-container {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 480px) {
  .auth0-lock-content {
    height: calc(var(--vh, 1vh) * 100 - 216px);
  }
  .auth0-lock-tabs-container + * {
    overflow: overlay !important;
    height: calc(var(--vh, 1vh) * 100 - 262px);
  }
  .auth0-lock-tabs-container + * > :last-child {
    margin-bottom: 50px;
  }
  .auth0-lock-form {
    padding-bottom: 0 !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-body-content {
    display: block !important;
  }

  .auth0-lock-form > *:not(.auth0-loading-screen) {
    /* padding-left: 20px !important; */
    /* padding-right: 20px !important;*/
  }

  .auth0-lock.auth0-lock .auth0-lock-terms {
    margin-top: -84px !important;
    position: relative !important;
    font-size: 0.7em !important;
    white-space: nowrap !important;
    bottom: 0 !important;
    transform: translateY(42px);
  }
}

.auth0-lock.auth0-lock .auth0-lock-tabs-container {
  margin: 0 !important;
  margin-bottom: 5px !important;
}

.auth0-lock-form {
  padding-top: 0 !important;
}

#undefined-close-button {
  display: none;
}

.auth0-lock-overlay {
  background: none !important;
}

.auth0-lock-widget {
  box-shadow: none !important;
}

.auth0-lock-header-bg-blur {
  background-image: none !important;
}

.auth0-lock-header-welcome {
  margin-top: 0.3em !important;
}

.auth0-lock-header {
  /*padding-top: 10px !important;*/
}

.auth0-lock-name {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  color: transparent !important;
  height: 2em !important;
  margin-top: 0 !important;
  margin-bottom: 0.3em !important;
  user-select: none;
}

.auth0-lock-header-bg,
.auth0-lock-header-bg-solid {
  background: transparent !important;
}

.auth0-lock-tabs {
  box-shadow: 0 -1px 0 0 rgba(92, 102, 111, 0.2) !important;
}

.auth0-lock-tabs-current {
  box-shadow: 0 -1px 0 0 #8051b0 !important;
}

:not(.auth0-lock-content-wrapper) + .auth0-lock-submit {
  border-radius: 0.3em !important;
  margin-bottom: 2em !important;
  box-shadow: 0 0 40px 0px #1111133d !important;
}

@media screen and (max-width: 480px) {
  .auth0-lock-submit {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    position: fixed;
    bottom: 0;
  }
}

.auth0-lock.auth0-lock {
  font-family: Comfortaa, cwTeXYen, Muli, Roboto, Helvetica Neue, Arial,
    sans-serif !important;
}

.auth0-lock.auth0-lock .auth0-lock-badge-bottom .auth0-lock-badge {
  display: flex !important;
  align-items: flex-end !important;
}

/* width */
.auth0-lock ::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 1em;
}

/* Track */
.auth0-lock ::-webkit-scrollbar-track {
  background: #00000000;
}

.auth0-lock ::-webkit-scrollbar-thumb {
  background: rgb(184, 159, 210);
  border-radius: 1em;
}

/* Handle on hover */
.auth0-lock ::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 130, 204);
}
