/*@tailwind preflight;*/

.bg-transparent {
  background-color: transparent
}

.bg-grey-lighter {
  --bg-opacity: 1;
  background-color: #f1f5f8;
  background-color: rgba(241, 245, 248, var(--bg-opacity))
}

.bg-white {
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-yellow {
  --bg-opacity: 1;
  background-color: #ffed4a;
  background-color: rgba(255, 237, 74, var(--bg-opacity))
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #3490dc;
  background-color: rgba(52, 144, 220, var(--bg-opacity))
}

.border-black {
  --border-opacity: 1;
  border-color: #22292f;
  border-color: rgba(34, 41, 47, var(--border-opacity))
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-b-16 {
  border-bottom-right-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem
}

.rounded-tr-16 {
  border-top-right-radius: 1.6rem
}

.rounded-tl-none {
  border-top-left-radius: 0
}

.rounded-br-none {
  border-bottom-right-radius: 0
}

.rounded-bl-none {
  border-bottom-left-radius: 0
}

.border-dashed {
  border-style: dashed
}

.border-none {
  border-style: none
}

.border-1 {
  border-width: 1px
}

.border-3 {
  border-width: 3px
}

.border {
  border-width: 1px
}

.border-b-0 {
  border-bottom-width: 0
}

.border-b-1 {
  border-bottom-width: 1px
}

.box-content {
  box-sizing: content-box
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: -webkit-flex;
  display: flex
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.items-start {
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.items-baseline {
  -webkit-align-items: baseline;
          align-items: baseline
}

.items-stretch {
  -webkit-align-items: stretch;
          align-items: stretch
}

.self-center {
  -webkit-align-self: center;
          align-self: center
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.justify-around {
  -webkit-justify-content: space-around;
          justify-content: space-around
}

.justify-evenly {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1
}

.flex-shrink {
  -webkit-flex-shrink: 1;
          flex-shrink: 1
}

.float-right {
  float: right
}

.font-200 {
  font-weight: 200
}

.font-300 {
  font-weight: 300
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.h-0 {
  height: 0
}

.h-8 {
  height: 0.8rem
}

.h-12 {
  height: 1.2rem
}

.h-16 {
  height: 1.6rem
}

.h-24 {
  height: 2.4rem
}

.h-32 {
  height: 3.2rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-60 {
  height: 6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-200 {
  height: 20rem
}

.h-256 {
  height: 25.6rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.text-36 {
  font-size: 3.6rem
}

.text-40 {
  font-size: 4rem
}

.text-64 {
  font-size: 6.4rem
}

.text-128 {
  font-size: 12.8rem
}

.text-xs {
  font-size: 1.2rem
}

.text-sm {
  font-size: 2.4rem
}

.leading-tight {
  line-height: 1.25
}

.m-0 {
  margin: 0
}

.m-8 {
  margin: 0.8rem
}

.m-12 {
  margin: 1.2rem
}

.m-16 {
  margin: 1.6rem
}

.m-20 {
  margin: 2rem
}

.m-24 {
  margin: 2.4rem
}

.m-auto {
  margin: auto
}

.-m-4 {
  margin: -0.4rem
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mt-0 {
  margin-top: 0
}

.mt-2 {
  margin-top: 0.2rem
}

.mb-2 {
  margin-bottom: 0.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-6 {
  margin-top: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.mb-40 {
  margin-bottom: 4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mr-48 {
  margin-right: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mt-auto {
  margin-top: auto
}

.ml-auto {
  margin-left: auto
}

.-mt-px {
  margin-top: -1px
}

.-mr-6 {
  margin-right: -0.6rem
}

.-ml-8 {
  margin-left: -0.8rem
}

.-mr-12 {
  margin-right: -1.2rem
}

.-ml-12 {
  margin-left: -1.2rem
}

.-ml-16 {
  margin-left: -1.6rem
}

.-mb-24 {
  margin-bottom: -2.4rem
}

.-ml-48 {
  margin-left: -4.8rem
}

.max-h-160 {
  max-height: 16rem
}

.max-h-full {
  max-height: 100%
}

.max-w-224 {
  max-width: 22.4rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-128 {
  min-height: 12.8rem
}

.min-h-px {
  min-height: 1px
}

.min-h-screen {
  min-height: 100vh
}

.min-w-0 {
  min-width: 0
}

.min-w-32 {
  min-width: 3.2rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-96 {
  min-width: 9.6rem
}

.min-w-128 {
  min-width: 12.8rem
}

.min-w-1\/3 {
  min-width: 33.33333%
}

.opacity-50 {
  opacity: .5
}

.opacity-100 {
  opacity: 1
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-6 {
  padding: 0.6rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-14 {
  padding: 1.4rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-48 {
  padding: 4.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pl-0 {
  padding-left: 0
}

.pr-4 {
  padding-right: 0.4rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pl-6 {
  padding-left: 0.6rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pr-48 {
  padding-right: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pb-96 {
  padding-bottom: 9.6rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.shadow-none {
  box-shadow: none
}

.focus\:shadow-none:focus {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-black {
  --text-opacity: 1;
  color: #22292f;
  color: rgba(34, 41, 47, var(--text-opacity))
}

.text-grey-dark {
  --text-opacity: 1;
  color: #8795a1;
  color: rgba(135, 149, 161, var(--text-opacity))
}

.text-grey {
  --text-opacity: 1;
  color: #b8c2cc;
  color: rgba(184, 194, 204, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-red {
  --text-opacity: 1;
  color: #e3342f;
  color: rgba(227, 52, 47, var(--text-opacity))
}

.text-green {
  --text-opacity: 1;
  color: #38c172;
  color: rgba(56, 193, 114, var(--text-opacity))
}

.text-purple-light {
  --text-opacity: 1;
  color: #a779e9;
  color: rgba(167, 121, 233, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.ordinal {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-words {
  overflow-wrap: break-word
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-12 {
  width: 1.2rem
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-32 {
  width: 3.2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-60 {
  width: 6rem
}

.w-64 {
  width: 6.4rem
}

.w-92 {
  width: 9.2rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-160 {
  width: 16rem
}

.w-256 {
  width: 25.6rem
}

.w-512 {
  width: 51.2rem
}

.w-xs {
  width: 32rem
}

.w-auto {
  width: auto
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.33333%
}

.w-2\/3 {
  width: 66.66667%
}

.w-1\/4 {
  width: 25%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-full {
  width: 100%
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-9999 {
  z-index: 9999
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.-translate-x-full {
  --transform-translate-x: -100%
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 900px) {

  .sm\:flex {
    display: -webkit-flex;
    display: flex
  }

  .sm\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-256 {
    height: 25.6rem
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:mr-16 {
    margin-right: 1.6rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:min-w-160 {
    min-width: 16rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .sm\:pl-24 {
    padding-left: 2.4rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.33333%
  }

  .sm\:w-2\/3 {
    width: 66.66667%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: -webkit-flex;
    display: flex
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row
  }

  .md\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start
  }

  .md\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%
  }

  .md\:h-auto {
    height: auto
  }

  .md\:max-w-none {
    max-width: none
  }

  .md\:min-w-192 {
    min-width: 19.2rem
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:hidden {
    display: none
  }

  .lg\:text-12 {
    font-size: 1.2rem
  }

  .lg\:text-16 {
    font-size: 1.6rem
  }

  .lg\:text-20 {
    font-size: 2rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }
}
